.bedankt {
  &__title {
    font-size: 24px;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
  }
  &__description {
    font-size: 14px;
    text-align: center;
    font-weight: normal;
  }

  &__btn {
    color: #000000;
    float: left;
    width: 100%;
    margin: auto;
    display: block;
    padding: 8px;
    box-shadow: inset 0px 0px 0px 1px#ffffff;
    margin-top: 20px;
    line-height: 100%;
    padding-top: 16px;
    margin-right: 0px;
    padding-bottom: 16px;
    text-transform: none;
    background-color: #ffffff;
    border: none !important;
    border-radius: 4px;
    &:hover {
      background-color: #e7e7e7;
    }
  }

  a {
    color: #4792da !important;
    text-decoration: underline;
  }
}
